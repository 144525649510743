import EnvConfig from '../config/EnvConfig.js';

const API_ENDPOINT = EnvConfig.terraform.api.invoke_url;

export const APIFetch = async ({ route, authToken, method, body }) => {
  return await fetch(`${API_ENDPOINT}/${route}`, {
    method: method ?? 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authToken,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
};
