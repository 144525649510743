import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function PTable(props) {
  const { sample, tableData, requirement } = props;

  const plant = requirement && (requirement.crop_class_id <= 2 || requirement.crop_class_id === 17);

  // console.log(sample);

  // Extract all the unique exchangeable_potassium values
  const columnValues = [
    ...new Set(
      tableData.phosphorus.map((el) => {
          return el.bses_phosphorus_max;
        }),
    ),
  ].sort((a, b) => a -b);

  const getCellColor = (correctPBIRange, correctCropClass, correctBSES) => {
    if (correctPBIRange && correctCropClass && correctBSES) return 'rgba(65, 172, 210, 0.6)';

    // return 'rgba(0, 161, 201, 0.1)';
    // return 'rgba(0, 157, 72, 0.1)';

    return '#FFF';
  };

  // console.log(tableData.potassium));

  const filterTable = (pbiRange, bses, plantRow, index) => {
    const filtered = tableData.phosphorus.filter((el) => {
      return (
        el.pbi_min <= pbiRange && el.pbi_max > pbiRange &&
        el.bses_phosphorus_min < bses &&
        el.bses_phosphorus_max >= bses
      );
    });

    if (filtered.length === 0) return <TableCell>0</TableCell>;
    const correctPBIRange = filtered[0].pbi_min <= sample.phosphorus_buffer_index && filtered[0].pbi_max > sample.phosphorus_buffer_index;
    const correctCropClass = plant === plantRow;
    const correctBSES = filtered[0].bses_phosphorus_min < sample.phosphorus_bses_mg_kg && filtered[0].bses_phosphorus_max >= sample.phosphorus_bses_mg_kg;

    return (
      <TableCell
        style={{
          background: getCellColor(
            correctPBIRange,
            correctCropClass,
            correctBSES,
          ),
        }}
      >
        {plantRow
          ? filtered[0].phosphorus_plant_application_rate
          : filtered[0].phosphorus_replant_and_ratoon_application_rate}
      </TableCell>
    );
  };

  return (
    <div>

      <Typography>PBI: {sample.phosphorus_buffer_index}</Typography>
      <Typography>BSES P (mg/kg): {sample.phosphorus_bses_mg_kg}</Typography>
      <TableContainer  style={{ marginBottom: 20}}>
        <Table stickyHeader size='small'>
          <TableHead>
          <TableRow>
              <TableCell rowSpan={2}>PBI</TableCell>
              <TableCell rowSpan={2}>P sorption class</TableCell>
              <TableCell rowSpan={2}>Crop</TableCell>
              <TableCell colSpan={columnValues.length}>BSES P (mg/kg) range and P application rate kg/ha</TableCell>
            </TableRow>
            <TableRow>
              {columnValues.map((el, index) => (
                <TableCell>
                  {index === columnValues.length - 1 ? `>= ${columnValues[columnValues.length - 2]}` : `< ${el}`}
                </TableCell>
              ))}
            </TableRow>
          
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCell rowSpan={2}>{`> 420`}</TableCell>
              <TableCell rowSpan={2}>{`Very High`}</TableCell>
              <TableCell>Plant and Replant</TableCell>
              {columnValues.map((el, index) => filterTable(421, el, true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Ratoon</TableCell>
              {columnValues.map((el, index) => filterTable(421, el, false, index))}
            </TableRow>

            <TableRow>
              <TableCell rowSpan={2}>{`281 - 420`}</TableCell>
              <TableCell rowSpan={2}>{`High`}</TableCell>
              <TableCell>Plant and Replant</TableCell>
              {columnValues.map((el, index) => filterTable(281, el, true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Ratoon</TableCell>
              {columnValues.map((el, index) => filterTable(281, el, false, index))}
            </TableRow>

            <TableRow>
              <TableCell rowSpan={2}>{`141 - 280`}</TableCell>
              <TableCell rowSpan={2}>{`Moderate`}</TableCell>
              <TableCell>Plant and Replant</TableCell>
              {columnValues.map((el, index) => filterTable(141, el, true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Ratoon</TableCell>
              {columnValues.map((el, index) => filterTable(141, el, false, index))}
            </TableRow>

            <TableRow>
              <TableCell rowSpan={2}>{`< 140`}</TableCell>
              <TableCell rowSpan={2}>{`Low`}</TableCell>
              <TableCell>Plant and Replant</TableCell>
              {columnValues.map((el, index) => filterTable(0, el, true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Ratoon</TableCell>
              {columnValues.map((el, index) => filterTable(0, el, false, index))}
            </TableRow>
            
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant='h6'
        style={{ paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {`Modifications to Phosphorus application rates where mill by-products have been applied`}
      </Typography>
      {requirement && requirement.mill_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_ash_rate} t/ha Mill Ash applied</Typography>}
      {requirement && requirement.mill_mud_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_rate} t/ha Mill Mud applied</Typography>}
      {requirement && requirement.mill_mud_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_ash_rate} t/ha Mill Mud Ash applied</Typography>}
      {requirement && !requirement.mill_mud_ash_rate && !requirement.mill_mud_rate && !requirement.mill_ash_rate && <Typography style={{ marginBottom: 10 }}>No Mill byproduct applied</Typography>}
      <TableContainer >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell >Mill by-product</TableCell>
              <TableCell>Application rate (wet t/ha)</TableCell>
              <TableCell>Phosphorus application strategy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
              <TableCell>Mill mud</TableCell>
              <TableCell>{`< 100`}</TableCell>
              <TableCell>No deduction</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mill mud</TableCell>
              <TableCell>{`100 - 300`}</TableCell>
              <TableCell>Nil P for at least one crop cycle</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='caption'>*Reef Water Quality guildlines</Typography>
    </div>
  );
}
