import { IconButton, TableCell, TableRow } from "@material-ui/core";

import { AddCircle } from "@material-ui/icons";
// import { Autocomplete } from "@material-ui/lab";
import ProductSelectLine from "./ProductSelectLine";
import React from "react"; //, { useCallback, useEffect, useState }

// import ButtonSelect from "../components/ButtonSelect";

// FUNCTIONS
import { sortProductsByAppTiming } from "../../functions/sortProducts";

export default function NutrientBlock(props) {
  const {
    block,
    products,
    addProductRow,
    removeProductRow,
    newComment,
    newAppTiming,
    newAppMethod,
    newProductSelected,
    newRateSelected,
  } = props;



  const ameliorantRequirements = () => {
    let ameliorant = null;
    if (
      parseInt(block.zinc) > 0 ||
      parseInt(block.copper) > 0 ||
      parseInt(block.lime_calcium) > 0 ||
      parseInt(block.lime_acidic) > 0 ||
      parseInt(block.magnesium) > 0 ||
      parseInt(block.gypsum) > 0 ||
      parseInt(block.silicate) > 0
    ) {
      ameliorant = "Ameliorants: ";
      ameliorant +=
        parseInt(block.zinc) > 0 ? block.zinc.toString() + "-Zn " : " ";
      ameliorant +=
        parseInt(block.copper) > 0 ? block.copper.toString() + "-Cu " : " ";
      ameliorant +=
        parseInt(block.lime_calcium) > 0 ? block.lime_calcium.toString() + "-Lca " : " ";
      ameliorant +=
        parseInt(block.lime_acidic) > 0 ? block.lime_acidic.toString() + "-Lph " : " ";
      ameliorant +=
        parseInt(block.magnesium) > 0 ? block.magnesium.toString() + "-Mg " : " ";
      ameliorant +=
        parseInt(block.gypsum) > 0 ? block.gypsum.toString() + "-Gp " : " ";
      ameliorant +=
        parseInt(block.silicate) > 0 ? block.silicate.toString() + "-Si" : "";
    }
    return ameliorant;
  }

  const millByproductsNote = () => {
    let note = null;
    if (
      parseInt(block.byproduct_applied) > 0
    ) {
      note = `Byproduct applied ${block.byproduct_applied}`;
    }
    return note;
  }


  return (
    <>
      <TableRow>
        <TableCell style={{padding: 0, backgroundColor: "rgba(0, 161, 201, 0.1)"}} />
        <TableCell
          style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
          align="left"
          colSpan={1}
        >
          Block: {block.block_name}
        </TableCell>
        <TableCell
          style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
          align="left"
          colSpan={1}
        >
          {Math.round(block.block_hectares * 100) / 100}ha
        </TableCell>
        <TableCell
          style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
          align="left"
          colSpan={2}
        >
          {block.crop_class_name}
        </TableCell>
        <TableCell
          style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
          align="left"
          colSpan={5}
        >
          {ameliorantRequirements()}
        </TableCell>
        <TableCell
          style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
          align="left"
          colSpan={3}
        >
          {millByproductsNote()}
        </TableCell>
        <TableCell>
          {block.to_fallow === null && <IconButton
            color="secondary"
            aria-label="arrow_back"
            onClick={addProductRow}
            size="small"
          >
            <AddCircle />
          </IconButton>}
        </TableCell>
      </TableRow>
      {sortProductsByAppTiming(block.productsSelected).map((productSelected, index) => (
        <ProductSelectLine
          key={index}
          products={products}
          area={parseFloat(block.block_hectares)}
          productSelected={productSelected}
          removeProductRow={() => removeProductRow(productSelected.originalIndex)}
          newComment={(comment) => newComment(comment, productSelected.originalIndex)}
          newAppTiming={(appTiming) => newAppTiming(appTiming, productSelected.originalIndex)}
          newAppMethod={(appMethod) => newAppMethod(appMethod, productSelected.originalIndex)}
          newProductSelected={(newProduct) =>
            newProductSelected(newProduct, productSelected.originalIndex)
          }
          newRateSelected={(newRate) => newRateSelected(newRate, productSelected.originalIndex)}
        />
      ))}
    </>
  );
}
